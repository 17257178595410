/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import LogRocket from 'logrocket';
import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from 'styled-components';
// import {Nav, NavFixed, NavGrid, Hamburger} from './common/navhome.js';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import("./hamburger.css");
import("../sass/index.scss");

LogRocket.init('hirlq6/rieldesign');

const LayoutWrapper = styled.section`
  /*comment*/
  margin-top:100px;
`

const Nav = styled.nav`
  text-align:right;
  a{
    padding-left:20px;
    &:hover{
      font-weight:700;
    }
  }
  @media(max-width:650px){
    grid-column-start: span 2;
  }
`

const NavFixed = styled.section`
  position:fixed;
  width:100%;
  background:#ffffff;
  top:0;
  z-index:1000;
`

const NavGrid = styled.section`
  width:1280px;
  margin:0 auto;
  display:grid;
  grid-template-columns:1fr 1fr;
  height:100px;
  align-items:center;
  background:#ffffff;
  .designer{
    font-weight:700;
  }
  @media(max-width:1350px){
    width:960px;
  }
  @media(max-width:1000px){
    width:100%;
    margin-left:-50%;
    padding:0 5%;
    box-sizing:border-box;
    left: 50%;
    position: absolute;
  }
  @media(max-width:650px){
    justify-items:center;
    .designer{
      grid-column-start: span 2;
    }
  }
  @media(max-width:450px){
    .navigation{
        position:absolute;
        display:none;
      &.active-ham{
        display:block;
        position:absolute;
        background:#ffffff;
        height:100vh;
        top:100px;
        width:100%;
        a{
          display:list-item;
          text-align:center;
          list-style-type:none;
          padding:0 0 20px 0;
        }
      }
    } 
  }
  a{
    color:#000;
    text-decoration:none;
    text-transform:uppercase;
    letter-spacing:2px;
  }
`

const Hamburger = styled.button`
  display:none;
  background: none;
  box-shadow: none;
  border: none;
  position: absolute;
  right: 30px;
  &:focus{
    outline:none;
  }
  @media(max-width:450px){
    display:block;
    grid-column-start: span 2;
  }
`

const Footer = styled.footer`
  width:1280px;
  margin:0px auto 50px auto;
  text-align:right;
  @media(max-width:1350px){
    width:960px;
  }
  @media(max-width:1000px){
    width:90%;
  }
  a{
    color:#d67e4a;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      datoCmsFooter {
        footerCopyright
        footerEmail
      }
      site {
        siteMetadata {
          title
        }
      }
      datoCmsSite {
        globalSeo {
          siteName
          titleSuffix
          twitterAccount
          facebookPageUrl
        }
      }
    }
  `)

  
  /* toggle menu function*/

  const [state, setState] = useState(false);

  function toggle() {
    setState(!state);
    console.log(state);
  }

  /* toggle menu function ends*/

  return (
    <>

    <HelmetDatoCms>
      <title>
        {data.datoCmsSite.globalSeo.siteName}
        -{data.datoCmsSite.globalSeo.titleSuffix}
        </title>
    </HelmetDatoCms>


      <LayoutWrapper>
        <NavFixed>
          <NavGrid>
            <Hamburger onClick={toggle} className={state ?  `hamTog hamburger1 is-active hamburger--squeeze` : `hamTog hamburger1 hamburger--squeeze`  } >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </Hamburger>
            <Link className="designer" to ="/">Riel Kitson</Link>
            <Nav className={state ?  `navigation active-ham` : `navigation`  }>
              <Link onClick={toggle} to ="/">Home</Link>
              <Link onClick={toggle} to ="/about">About</Link>
              <a href="/resume">Resume</a> 
              <Link onClick={toggle} to ="/contact">Contact</Link>
            </Nav>
          </NavGrid>
        </NavFixed>
        
        
        <main>{children}</main>
        <Footer>
        {data.datoCmsFooter.footerCopyright}
          <span> | </span> 
        <a href={`mailto:` + data.datoCmsFooter.footerEmail}>
          {data.datoCmsFooter.footerEmail}
        </a>
        </Footer>
      </LayoutWrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
